@font-face {
  font-family: 'Merriweather';
  src: url('../src/assets/fonts/Merriweather-Black.woff2') format('woff2'),
      url('../src/assets/fonts/Merriweather-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../src/assets/fonts/Merriweather-Regular.woff2') format('woff2'),
      url('../src/assets/fonts/Merriweather-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../src/assets/fonts/OpenSans-Regular.woff2') format('woff2'),
      url('../src/assets/fonts/OpenSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../src/assets/fonts/OpenSans-Italic.woff2') format('woff2'),
      url('../src/assets/fonts/OpenSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

body,
html {
  font-family: 'Open sans', sans-serif;
  font-weight: 400;
  color: #333;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}

#root,
#root>div {
  min-height: 100vh;
}

#root>div {
  display: grid;
  grid-template-rows: auto;
  background: #fff;
}

/* #root>div::after {
  content: "";
  display: block;
  grid-row: -1;
  height: 0;
} */

h3 {
  font-size: 2em;
  margin-bottom: 1.5em;
  font-family: 'Merriweather';
  position: relative;
  margin-top: 1em;
}

h3:after {
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -25px;
  width: 50px;
  height: 6px;
  border-radius: 5px;
  background: #ffffff;
  mix-blend-mode: difference;
  bottom: -25px;
}

h4 {
  margin-top: 0;
}

h4.section-header {
  padding: 10px 0;
  font-size: 1.75em;
  display: block;
  font-family: Merriweather;
  font-weight: 900;
  margin-bottom: .5em;
  position: relative;
}

p {
  margin-top: 0;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 1.3;
}

.content {
  max-width: 1300px;
  text-align: center;
  padding: 0 30px 0;
  margin: 0 auto;
}

.contentNarrow {
  max-width: 1000px;
  margin: 0 auto;
}

.pTB30 {
  padding: 30px 0;
}

.pTB60 {
  padding: 60px 0;
}

.textLeft {
  text-align: left;
}

.textCenter {
  text-align: center;
}

.nav-visible *, .nav-visible *::after, .nav-visible *::before {
  animation-play-state: paused !important;
}

.team header span {
  color: #333 !important
}
.team header svg path {
  fill: #333 !important;
}

@media only screen and (min-width: 1024px) {
  body {
    font-size: 18px;
  }

  h3 {
    font-size: 2.5em;
  }
}